import React, { useEffect, useState } from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import NavigationItems from '../NavigationItems'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTwitter, faInstagram, faDiscord, faMedium } from '@fortawesome/free-brands-svg-icons'
import ModelViewer from '../ModelViewer'
// import ImageCarousel from './imageCarousel.js'
import Data from '../../repositories/data';

const storageRoot = "https://firebasestorage.googleapis.com/v0/b/conservation-nft.appspot.com/o/"
const storageHomeRoot = storageRoot+"web%2Fhome%2F"
// const storageSpeciesRoot = storageRoot+"species%2F"
const storageModelsRoot = storageRoot+"models%2F"

const Home = () => {

  const [droneItems, setDroneItems] = useState([
    { key: "loading", y: 0, content: `Intel incoming


██           ██
██           ██
    `, class: "eyes" },
    { key: "spring", y: 0, content: "The utopia Aniland has been protected by international law for over 200 years" },
    { key: "autumn", y: 0, content: "With those laws now repealed the many species of Anibles there are at risk" },
    { key: "greedy", y: 0, content: "Intel shows that the megacorp Greedy Inc is planning to exploit Aniland's resources" },
    { key: "roadmap", y: 0, content: "Our new global coalition - ARC - is assembling a task force to intercept them" }, 
    { key: "overwatch", y: 0, content: "Ranger - we need you in the field protecting these Anibles!" },
    { key: "summer", y: 0, content: "We've chosen just 60 of the most iconic species to release as digital collectibles" }
  ])
  const [currentDroneItem, setCurrentDroneItem] = useState(droneItems[0])

  // useEffect(() => {
  //   const changeDroneItemIfNecessary = () => {
  //     droneItems.every(theDroneItem => {
  //       const top = window.pageYOffset || document.documentElement.scrollTop;
  //       if(top + 475 >= theDroneItem.y) {
  //         setCurrentDroneItem(theDroneItem);
  //         return true;
  //       }
  //       return false;
  //     });
  //   }
  //   setCurrentDroneItem(droneItems[0])
  //   setTimeout(function() {
  //     var newDroneItems = droneItems;
  //     droneItems.forEach(function (theDroneItem, index) {
  //       if(theDroneItem.key !== "loading") {
  //         const boundingRect = document.getElementById(theDroneItem.key).getBoundingClientRect();
  //         const top = window.pageYOffset || document.documentElement.scrollTop;
  //         newDroneItems[index].y = top+boundingRect.y;
  //       }
  //     });
  //     setDroneItems(newDroneItems);
  //     changeDroneItemIfNecessary();
  //     window.addEventListener('scroll', changeDroneItemIfNecessary, true);
  //   }, 6000);
  //   window.onbeforeunload = function () {
  //     window.scrollTo(0, 0);
  //   }
  //   return () => window.removeEventListener('scroll', changeDroneItemIfNecessary);
  // }, [droneItems]);

  // useEffect(() => {
  //   var index = 0;
  //   function addItem() {
  //     if(!document.hasFocus()) return;
  //     var swimlaneNumber = index % 2 === 0 ? 1 : 2;
  //     var span = document.createElement("SPAN");
  //     const text = document.createTextNode(""/*Data.roadmap[index] Removed roadmap temporarily*/);
  //     span.appendChild(text);
  //     document.getElementById("swimlane"+swimlaneNumber).appendChild(span);
  //     index = index === Data.roadmap.length-1 ? 0 : index + 1;
  //   }
  //   addItem();
  //   const interval = setInterval(() => {
  //     addItem();
  //   }, 3000);
  //   return () => clearInterval(interval);
  // }, []);

  // If you want to reorder the sections, you'll need to change the order of droneItems
  return (
    <div className="homeContainer">
      {/* <div id="anidrone">
        <div className="propeller left"><img src="/img/home/propeller.png" alt="Anidrone's spinning propeller"/></div>
        <div className="propeller right"><img src="/img/home/propeller.png" alt="Anidrone's spinning propeller"/></div>
        <img className="drone" src="/img/home/anidrone.png" alt="AniDrone floating companion"></img>
        <p className={currentDroneItem.class}>{currentDroneItem.content}</p>
        {currentDroneItem.key!=="loading"?<span>Solar charge ■■■□</span>:null}
      </div> */}

      <section id="spring" className="fullWidthImageContainer">
        <Container fluid>
          <div className="bannerContainer">
            <div className="logoNameContainer">
              <img src="img/logo-full.png" alt="Anibles Logo" className="logo notRounded"/>
            </div>
            <img src={storageHomeRoot+"spring.jpg?alt=media"} alt="Welcome to the Anible Kingdom"/>
          </div>
        </Container>
      </section>

      <section className="announcements">
        <h1>Launching Soon!</h1>
      </section>

      <div id="autumn" className="fullWidthImageContainer">
        <img src={storageHomeRoot+"autumn.jpg?alt=media"} alt="An autumnul Anible bear scene" />
      </div>


      {/* <section className="interspersedInfo nftCarousel">
        <ImageCarousel images={createAllShowcaseImages(storageSpeciesRoot)} />
      </section> */}

      <section id="greedy" className="fullWidthImageContainer">
        <Container fluid>
          <div>
            <img src={storageHomeRoot+"greedyinc.jpg?alt=media"} alt="A meeting at Greedy Inc headquarters" />
          </div>
        </Container>
      </section>

      {/* <section id="roadmap-top" className="announcements">
        <h1>Construction of THE ARC is nearing completion</h1>
      </section> */}

      {/* <section id="roadmap" className="fullWidthImageContainer">
        <Container fluid className="roadmapDisplay">
          <div className="gridBackground"></div>
          <p>CONFIDENTIAL: Top-down blueprint - Anible Ranger Coalition ship [CODENAME: THE ARC]</p>
          <div id="swimlane1" className="swimlane one"></div>
          <div id="swimlane2" className="swimlane two"></div>
          <img src={storageHomeRoot+"ARC-ship-landscape.png?alt=media"} alt="A blueprint of the ARC ship" className="blueprint desktopBlueprint" />
          <img src={storageHomeRoot+"ARC-ship-portrait.png?alt=media"} alt="A blueprint of the ARC ship" className="blueprint mobileBlueprint" />
        </Container>
      </section> */}


      <section id="cta" className="interspersedInfo">
        <Container>
          <h1>Ranger Enrolment</h1>
          <Row>
            {/* <Col md={6}>
              <p>Join our Discord</p>
              <a href="/discord/join" target="_blank" rel="noopener noreferrer" className="buttonLink">Join</a>
            </Col>
            <Col md={6}>
              <p>Join our mailing list</p>
              <form action="https://anibles.us14.list-manage.com/subscribe/post?u=2716123097ef84183c533b6a9&amp;id=b06a336da7" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" className="validate" target="_blank" noValidate>
                <div id="mc_embed_signup_scroll">
                    <div className="input-group">
                        <input type="email" name="EMAIL" className="form-control email" id="mce-EMAIL"/>
                        <div className="input-group-append">
                          <button type="submit" name="subscribe" id="mc-embedded-subscribe" className="btn button clickable">Join</button>
                        </div>
                    </div>
                    <div id="mce-responses" className="clear foot">
                        <div className="response" id="mce-error-response" style={{display: "none"}}></div>
                        <div className="response" id="mce-success-response" style={{display: "none"}}></div>
                    </div>
                    <div style={{position: "absolute", left: "-5000px"}} aria-hidden="true"><input type="text" name="b_2716123097ef84183c533b6a9_b06a336da7" tabIndex="-1" defaultValue=""/></div>
                </div>
              </form>
            </Col> */}
            <p className="socialLinks"><a href="/discord/join" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faDiscord} /></a> | <a href="https://twitter.com/_anibles" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faTwitter} /></a> | <a href="https://instagram.com/_anibles" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faInstagram} /></a>  | <a href="https://blog.anibles.io" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faMedium} /></a></p>
          </Row>
        </Container>
      </section>


      <section id="overwatch" className="fullWidthImageContainer">
        <Container fluid>
          <div>
            <img src={storageHomeRoot+"overwatch.jpg?alt=media"} alt="An ARC Ranger watches over a grizzly bear whilst a pair of ducks swim down the river that separates them" />
          </div>
        </Container>
      </section>


      <section className="interspersedInfo">
        <Container>
          <Row>
            <Col md={4}>
              <ModelViewer src={storageModelsRoot+"elephant.gltf?alt=media"} height={300} />
            </Col>
            <Col md={4}>
              <ModelViewer src={storageModelsRoot+"brownbear.gltf?alt=media"} height={300} />
            </Col>
            <Col md={4}>
              <ModelViewer src={storageModelsRoot+"gorilla.gltf?alt=media"} height={300} />
            </Col>
          </Row>
        </Container>
      </section>


      <section id="summer" className="fullWidthImageContainer">
        <Container fluid>
          <div>
            <img src={storageHomeRoot+"summer.jpg?alt=media"} alt="An Aniland plains scene, with lions, rocks and bones in the foreground, and elephants and giraffe in the distance" />
          </div>
        </Container>
      </section>


      <section id="footer">
        <footer>
          <NavigationItems className="navContainer navContainerFooter" />    
          <p>Copyright {(new Date()).getFullYear()} &copy; Anibles. All Rights Reserved.</p>
        </footer>
      </section>



    </div>
  );
}

// --------------------- Components & Util ---------------------

function createAllShowcaseImages(root) {
  function item(species, type="base") {
    return {url: root+species+"-"+type+".jpg?alt=media", alt: " NFT showcase "+type+" "+species}
  }
  return [item("Buffalo"), item("Elephant"), item("Giraffe"), item("Gorilla"), item("GrizzlyBear"), item("Kangaroo"), item("Lion"), item("WhiteRhino"), item("EmperorPenguin"), item("WhiteRhino", "albino"), item("GreenSeaTurtle"), item("HammerheadShark"), item("Mallard"), item("GreenSeaTurtle", "albino")]
}

export default Home;