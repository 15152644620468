import React from 'react';
import { Route, BrowserRouter as Router } from 'react-router-dom';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import NavigationItems from '../NavigationItems';
import Container from 'react-bootstrap/Container';
import Home from '../Home';
import Team from '../Team';
import Intel from '../Intel';
import NFTs from '../NFTs';
import Store from '../Store';
import StoreProductItem from '../StoreProductItem';
import PaymentSuccess from '../PaymentSuccess';
import ModelPreview from '../ModelPreview';
import Calculator from '../Calculator';
import RangerBuilder from '../RangerBuilder';
import SpeciesList from '../SpeciesList';
import Species from '../Species';

class App extends React.Component {
    render() {
        return (
            <Router>
                <Navbar bg="transparent" expand={ window.location.pathname === "/" ? "xl" : "sm" }>
                    <Navbar.Brand href="/">{ window.location.pathname === "/" ? <div style={{height:55}}></div> : <img src="/img/logo.png" alt="Anibles Logo" height="55" className="notRounded"/>}</Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="mr-auto"></Nav>
                        <Nav>
                            <NavigationItems className="navContainerNormal" />
                        </Nav>
                    </Navbar.Collapse>
                </Navbar>
                <Container className="mainContainer" fluid>
                    <Route exact path="/" component={Home} />
                    <Route exact path="/team" component={Team}/>
                    <Route exact path="/intel" component={Intel}/>
                    <Route exact path="/collectibles" component={NFTs}/>
                    <Route exact path="/store" component={Store}/>
                    <Route exact path="/store/:productId/:productItemId" component={StoreProductItem}/>
                    <Route exact path="/payment-success" component={PaymentSuccess}/>
                    <Route exact path="/calculator" component={Calculator}/>
                    <Route exact path="/species" component={SpeciesList} />
                    <Route exact path="/species/:id" component={Species} />
                    <Route exact path="/model/:name" component={ModelPreview} />
                    <Route exact path="/ranger-builder" component={RangerBuilder} />
                    <Route exact path="/discord" component={() => { window.location.href ="https://discord.com/channels/926531162346782752/926531162346782757"; return null }} />
                    <Route exact path="/discord/join" component={() => { window.location.href ="https://discord.gg/DZ9AMmCv8H"; return null }} />
                </Container>
            </Router>
        )
    }
}

export default App;