import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';

const PaymentSuccess = () => {
    
    return (
        <div className="baseContainer paymentSuccessContainer">
            <Container className="raisedContentContainer">
                <Row>
                <Col>
                    <h1>Purchase Successful</h1>
                    <p>Thank you! Your payment has been received, and we are now working on getting your item(s) to you.</p>
                    <p>Should you have any issues or questions, please get in touch with us at <a href="mailto:contact@anibles.io">contact@anibles.io</a>.</p>
                    <Button href="/store" className="buttonLink primary">Back to shop</Button>
                </Col>
            </Row>
            </Container>
        </div>
    )
}

export default PaymentSuccess;