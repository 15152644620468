const stickerGenericProductInfo = "100% of profits of these first edition stickers go to hand-picked conservation causes.\n\nProduct information:\n- Bio-degradable*\n- Vegan*\n- Eco-friendly*\n- Recyclable\n- Roughly 2.5 inches/6.3 cm\n- Best suited for indoor use\n- Splash resistant\n- Scratch resistant\n* Non-holographic stickers only\n\nWe currently ship to the UK, USA, Canada and Europe. Shipping is free."
const products = [
  {
    title: "Games",
    images: ["games-0.jpg", "games-1.jpg", "games-2.jpg"],
    imageAlts: ["A screenshot of an Anibles mobile game showing an island scene", "A screenshot of an Anibles mobile game showing a bear in a snowy environment", "A screenshot of an Anibles mobile game showing a desert scene"],
    text: "Details coming soon",
    interval: 2757,
    status: "Upcoming"
  },
  {
    title: "Toys",
    images: ["toys-0.jpg", "toys-1.jpg", "toys-2.jpg"],
    imageAlts: ["Our bear and lion toys on a desk", "Our bear toy being held up outside", "Our lion toy on a desk"],
    text: "Details coming soon",
    interval: 2535,
    status: "Upcoming"
  },
  {
    title: "Collectibles",
    images: ["nfts-0.jpg", "nfts-1.jpg", "nfts-2.jpg"],
    imageAlts: ["One of our NFTs, a giraffe", "One of our NFTs, a gorilla", "One of our NFTs, a penguin"],
    text: "Mint coming soon",
    interval: 3010,
    status: "Upcoming"
  },
  {
    title: "Stories",
    images: ["stories-0.jpg", "stories-1.jpg", "stories-2.jpg"],
    imageAlts: ["A storyboard for an Anible story", "A child reading one of our books", "A child watching one of our animated cartoons on an iPad"],
    text: "Details coming soon",
    interval: 3256,
    status: "Upcoming",
  },
  {
    id: "stickers",
    title: "Sticker Packs",
    status: "Available",
    items: [
      {
        id: "grizzly-sticker-pack",
        title: "Grizzly Sticker Pack",
        images: ["sticker-GrizzlyBear-0.png"],
        price: "£6.00 · $7.00 US · $9.00 CAD · €7.00",
        priceIds: {
          dev: "price_1Lidk3H3AY1dbrXphLDV7eCP",
          prod: "price_1LzyEnH3AY1dbrXpELC3DLxf"
        },
        description: "Stick 'Ursus arctos horribilis' on your laptop and be taken back to your roots in the forest every time you glance at it.\n\nComes as a pack of 5 unique Anibles, at least 2 of which will be grizzly bears, with a chance to receive an additional rare holographic of the grizzly main character, Autumn.\n\n"+stickerGenericProductInfo
      },
      {
        id: "elephant-sticker-pack",
        title: "Elephant Sticker Pack",
        images: ["sticker-Elephant-0.png"],
        price: "£6.00 · $7.00 US · $9.00 CAD · €7.00",
        priceIds: {
          dev: "price_1LidSRH3AY1dbrXpaiwZH8Fm",
          prod: "price_1LzyExH3AY1dbrXpAriFbH8f"
        },
        description: "Gain the wisdom of the herd with 5 unique Anible stickers, at least 2 of which will be elephants.\n\nThere's a chance you'll receive an additional rare holographic of the elephant main character, Mosi.\n\n"+stickerGenericProductInfo
      },
      {
        id: "penguin-sticker-pack",
        title: "Penguin Sticker Pack",
        images: ["sticker-EmperorPenguin-0.png"],
        price: "£6.00 · $7.00 US · $9.00 CAD · €7.00",
        priceIds: {
          dev: "price_1LidmBH3AY1dbrXpufMU4znf",
          prod: "price_1LzyF1H3AY1dbrXpKocBHq1I"
        },
        description: "Perfect for the outside of the freezer, our penguin stickers are looking for a cold environment to call home.\n\nComes as a pack of 5 unique Anibles, at least 2 of which will be penguins, with a chance to receive an additional rare holographic of the penguin main character, Myla.\n\n"+stickerGenericProductInfo
      },
      {
        id: "mallard-sticker-pack",
        title: "Mallard Sticker Pack",
        images: ["sticker-Mallard-0.png"],
        price: "£6.00 · $7.00 US · $9.00 CAD · €7.00",
        priceIds: {
          dev: "price_1Lidn6H3AY1dbrXpAOxeded3",
          prod: "price_1LzyF7H3AY1dbrXp284NvW7g"
        },
        description: "Make sure you grab a mallard sticker pack before they fly out of stock!\n\nComes as a pack of 5 unique Anibles, at least 2 of which will be mallards, with a chance to receive an additional rare holographic of the mallard main character, Brooke.\n\n"+stickerGenericProductInfo
      }
    ]
  }
]

const utility = [
  {
    title: "Royalties",
    image: "img/home/utility-coin.png",
    imageAlt: "Coin"
  },
  {
    title: "Airdrop",
    image: "img/home/utility-egg.png",
    imageAlt: "Egg"
  },
  {
    title: "Gaming",
    image: "img/home/utility-gamepad.png",
    imageAlt: "Gamepad"
  },
  {
    title: "Giveaways",
    image: "img/home/utility-bag.png",
    imageAlt: "Shopping bag"
  },
  {
    title: "Trips",
    image: "img/home/utility-binoculars.png",
    imageAlt: "Binoculars"
  }
]

const people = [
  {
    image: "img/RP.jpeg",
    link: "https://www.linkedin.com/in/rosspatman/",
    linkText: "LinkedIn",
    name: "Ross Patman",
    role: "Co-founder",
    description: "Ross has been a software engineer since 2012, and has been coding for nearly 20 years. He's worked on everything from mobile apps/games, to Ethereum ÐApps, to security systems in African national parks. He's led high profile software launches for huge brands, such as Bentley, Virgin Active, The O2 and LA Galaxy, which have included innovative tech such as emotion recognition and augmented reality."
  },
  {
    image: "img/LF.jpeg",
    link: "https://www.linkedin.com/in/luke-faxon-231a7312/",
    linkText: "LinkedIn",
    name: "Luke Faxon",
    role: "Co-founder",
    description: "Working on the visuals and setting the product direction is illustrator and concept artist Luke. If you've seen something beautiful here, it's because he's had his discerning eye on it. Luke's been creating amazing characters and environments for over 10 years, and loves worldbuilding. He has a professional background in storyboarding, visual merchandising, and graphic design."
  },
  {
    image: "img/ER.jpeg",
    link: "https://www.linkedin.com/in/evanrapoport/",
    linkText: "LinkedIn",
    name: "Evan Rapoport",
    role: "Advisor",
    centered: true,
    description: "Evan leads the Moonshots program at Google Brain, which advances AI research, and was previously a Product Manager on Google Maps and Android. While at Google X, he founded and led a moonshot project (Tidal) focused on ocean conservation and sustainable seafood. Evan has extensive experience in conservation technology and impact investing, having been the founding CEO of a major ocean conservation foundation (Oceankind) and the Senior Technology Fellow at Conservation International. He is an investor in MetaGood (NFTs for good) and a volunteer advisor for a few nonprofit organizations focused on community-based wildlife protection in Kenya and across Africa."
  },
  {
    image: "img/mysteryperson.jpg",
    link: "https://workinstartups.com/job-board/job/122953/community-marketing-manager-at-anibles/",
    linkText: "Apply",
    name: "You?",
    role: "Community/marketing manager",
    description: "We're looking for someone to join us on a part-time basis to help build our community and market Anibles. We're looking for someone well versed in building communities, especially in the NFT or blockchain space. Having a passion for animals and their conservation would be a huge bonus."
  },
  {
    image: "img/mysteryperson.jpg",
    name: "You?",
    role: "Blockchain developer",
    description: "Have you been searching for a way to use your tech skills for good? Look no further! We want to bring another developer on to the team, one who specialises in Blockchain technologies. You'll be helping us create safe, feature-rich contracts. Please get in touch if you're a good fit and interested!"
  }
]

const roadmap = [
  "Public launch",
  "Whitelist mint completes",
  "Full mint completes",
  "2500 eggs airdropped",
  "100 NFTs to random minters",
  "100 NFTs to top adopters",
  "Random minters sent on trips",
  "Web comics launched",
  "Pilot animation airs",
  "App launched",
  "Hard toy to holders of a species",
  "Soft toy prototypes begin",
  "Hard toys in online store",
  "Game + metaverse development begins"
];

const faqs = [
  {
    question: "How do you pronounce it?",
    answer: "A-nee-bulls!"
  },
  {
    question: "Why should I care?",
    answer: "We believe that you should feel good about doing something for animals. That's the founding principle of Anibles. No guilt-tripping, no solicitation, just the power of a strong brand used for good."
  },
  {
    question: "Are there benefits to joining early?",
    answer: "Yes! Our earliest community is helping us shape Anibles into the wildlife brand of the future, and will be rewarded for doing so. For instance, those who manage to acquire digital collectibles stand to benefit from royalties and appreciation of their asset(s)."
  },
  {
    question: "How are the digital collectibles released?",
    answer: "They will be released as standard ERC-721 NFTs, on an eco-friendly blockchain, available on major exchanges, with 2D and 3D assets."
  },
  {
    question: "What about the other products?",
    answer: "Our physical items, such as toys, collectible cards, stickers and books, will be sold on in stores (online and in the real-world). Games and stories will be available digitally."
  },
  {
    question: "How are Anibles created?",
    answer: "We have developed our own custom generation algorithm that selects unique random combinations of traits. It hooks into our 3D models and can generate over 1.6m unique Anibles."
  },
]

function unknown(type) {
  return {
    id: "Unknown",
    name: "Unknown",
    traitCategories: ["Unknown", "Unknown", "Unknown"],
    type: type,
    description: "Our AniDrones are hard at work identifying all Anible species.\n\nCheck back again soon for their latest report!"
  }
}

const species = [
  unknown("Land"),
  {
    id: "Buffalo",
    name: "Buffalo",
    traitCategories: ["Horns", "Ears", "Snout"],
    type: "Land",
    description: "This species is known to be grumpy and unpredictable, and they can weigh over 800kg of mostly muscle and sharp horn.\n\nIt goes without saying, therefore, that they should be treated with caution!"
  },
  unknown("Land"),
  unknown("Land"),
  unknown("Land"),
  {
    id: "Elephant",
    name: "Elephant",
    traitCategories: ["Tusks", "Ears", "Trunk"],
    type: "Land",
    description: "Weighing up to 2.5 tonnes, these are the largest land mammals on Aniland. They're very clever. Probably more intelligent than you in fact.\n\nHighly social, you'll often find them frolicking in a watering hole, chasing away the hippos.",
    mainCharacter: {
      name: "Mosi",
      description: `Mosi is a 3 year old elephant. He is small, has large black eyes, small ears, small tusks, and a long trunk.
He is innocent, lovable, and curious, and enjoys playing with the other calves, but is often naïve and lands himself in trouble.

The waterhole where Mosi and his herd drink has recently started to dry out, and so a perilous journey lays ahead, which will take them across the dry plains and jagged mountains of Aniland in search of a new source of water.

As well as the treacherous terrain, they will have to deal with the newly arrived Greedy Inc and the difficulties they will surely bring.`,
    }
  },
  {
    id: "Giraffe",
    name: "Giraffe",
    traitCategories: ["Pattern", "Horns", "Wildcard"],
    type: "Land",
    description: "Oh to reach the lofty heights of the giraffe; able to see for miles and reach the sweetest delights at the very tops of the trees.\n\nThey can stand up 30 minutes after being born, and run after just 10 hours."
  },
  {
    id: "Gorilla",
    name: "Gorilla",
    traitCategories: ["Ears", "Mouth", "Wildcard"],
    type: "Land",
    description: "Thankfully they are 'gentle giants', as they are more than 10 times stronger than the average human despite being the same height.\n\nGorillas on Aniland love to laze around in the forest surrounded by their respective troops."
  },
  unknown("Land"),
  {
    id: "GrizzlyBear",
    name: "Grizzly Bear",
    traitCategories: ["Ears", "Claws", "Snout"],
    type: "Land",
    description: "'Ursus arctos horribilis' doesn't sound so nice, and if you happen on a mother with her cubs the 'horribilis' part may make sense.\n\nMost of the time though they'll be foraging for fruit, berries, nuts and honey.\n\nThe big hump on a grizzly's back is muscle that helps it to dig.",
    mainCharacter: {
      name: "Autumn",
      description: `Autumn is an 8 year old Grizzly Bear. She is 300 pounds (large for her species), has large amber eyes, double folded ears, small claws, and a coloured snout.

Not long ago Autumn gave birth to her first litter of cubs, and is adapting to the chaos of being a new bear mother.
Her two cubs are a mixture of shy and mischievous, and are keeping her on her toes with their rambunctious behaviour.
They forage for food from their den in the forest, snacking on berries, fish, and honey when they can find it.

Since Greedy Inc arrived, Autumn has been having trouble finding food, especially from the river where she takes the cubs to learn how to fish.
Will she be able to find enough food to ensure they survive the harsh winter months ahead?`,
    }
  },
  unknown("Land"),
  {
    id: "Kangaroo",
    name: "Kangaroo",
    traitCategories: ["Ears", "Legs", "Belly"],
    type: "Land",
    description: "The roo is a bouncy marsupial that can hop 25 feet in a single bound.\n\nThey use their tail as another limb, which is especially useful for standing on during boxing matches with their rivals.\n\nGroups of kangaroos on Aniland are called mobs."
  },
  unknown("Land"),
  {
    id: "Lion",
    name: "Lion",
    traitCategories: ["Ears", "Claws", "Snout"],
    type: "Land",
    description: "Have you ever woken in your tent in the dawn light to a lion outside licking the dew off the material? Best to stay still and not make a sound!\n\nLions on Aniland are particularly cunning, and extremely loyal to their pride."
  },
  unknown("Land"),
  unknown("Land"),
  unknown("Land"),
  unknown("Land"),
  unknown("Land"),
  unknown("Land"),
  unknown("Land"),
  unknown("Land"),
  unknown("Land"),
  {
    id: "WhiteRhino",
    name: "White Rhino",
    traitCategories: ["Ears", "Horns", "Wildcard"],
    type: "Land",
    description: "Their name derives from the Afrikaans word 'weit', meaning wide and referring to their wide mouths.\n\nTheir have poor vision, like to stay in open grassland, and tend to run from threats."
  },
  unknown("Land"),
  unknown("Water"),
  unknown("Water"),
  unknown("Water"),
  unknown("Water"),
  unknown("Water"),
  {
    id: "EmperorPenguin",
    name: "Emperor Penguin",
    traitCategories: ["Beak", "Flippers", "Wildcard"],
    type: "Water",
    description: "“Take it all in all, I do not believe anybody on Earth has it worse than an Emperor Penguin.” - Apsley Cherry-Garrard.\n\nCould you protect an egg through an endless -40 °C winter?",
    mainCharacter: {
      name: "Myla",
      description: `Myla is a 6 year old emperor penguin. She is an average size with medium blue eyes, has a crooked orange beak, matching orange feet, and long flippers.
She is very quiet and introverted, however, she is bright and always finds a way to get herself out of trouble.      

Myla's tendency to be amongst herself and out of the huddle has landed her into a spot of trouble. The ice has fractured, and Myla has awoken to find herself floating across the ocean, far away from her huddle.

After weeks alone wandering the ice, Myla will have to use every ounce of her wit and intelligence in order to get her back to safety, and will soon realise the importance of community and friendship.`,
    }
  },
  unknown("Water"),
  {
    id: "GreenSeaTurtle",
    name: "Green Sea Turtle",
    traitCategories: ["Shell", "Flippers", "Head"],
    type: "Water",
    description: "Drifting along in ocean currents around Aniland, this marine reptile loves nothing more than snacking on seaweed and sea grass.\n\nThey'll rest on the seafloor for up to five hours before coming up for a breath."
  },
  {
    id: "HammerheadShark",
    name: "Hammerhead Shark",
    traitCategories: ["Pectoral Fins", "Dorsal Fin", "Wildcard"],
    type: "Water",
    description: "The position of their eyes on the sides of their wide head gives them a 360 degree vision of their underwater world.\n\nThey use that field of vision to look for fish, octopuses, squid and crustaceans.\n\nThey don't seek out human prey, but will attack if provoked."
  },
  unknown("Water"),
  unknown("Water"),
  unknown("Water"),
  unknown("Water"),
  unknown("Water"),
  unknown("Water"),
  unknown("Water"),
  unknown("Water"),
  unknown("Water"),
  unknown("Water"),
  unknown("Water"),
  unknown("Water"),
  unknown("Air"),
  unknown("Air"),
  unknown("Air"),
  unknown("Air"),
  unknown("Air"),
  unknown("Air"),
  unknown("Air"),
  unknown("Air"),
  unknown("Air"),
  {
    id: "Mallard",
    name: "Mallard",
    traitCategories: ["Wings", "Beak", "Wildcard"],
    type: "Air",
    description: "These aquatic birds drift quietly through the rivers and waterways of Aniland.\n\nNew romantic bonds are formed each season, with a vibrant beak that signifies good health being an attractive trait."
  },
  unknown("Air"),
  unknown("Air"),
  unknown("Air"),
  unknown("Air")
]

const data = {
    utility: utility,
    products: products,
    roadmap: roadmap,
    people: people,
    faqs: faqs,
    species: species
}

export default data;

/*
  UNUSED

    levels: levels,
    sizes: sizes,
    eyeColours: eyeColours,
    eyeSizes: eyeSizes,
    traits: traits,
    albino: albino,

  const sizes = [
  {
    title: "Tiny",
    colourClass: "common",
    value: 963,
    total: 10000,
    score: 2
  },
  {
    title: "Normal",
    colourClass: "common",
    value: 5566,
    total: 10000,
    score: 0
  },
  {
    title: "Big",
    colourClass: "common",
    value: 2830,
    total: 10000,
    score: 1
  },
  {
    title: "Huge",
    colourClass: "common",
    value: 641,
    total: 10000,
    score: 3
  }
]

const eyeColours = [
  {
    title: "Black",
    colourClass: "black",
    value: 4041,
    total: 10000,
    score: 0
  },
  {
    title: "Brown",
    colourClass: "eyesBrown",
    value: 2383,
    total: 10000,
    score: 1
  },
  {
    title: "Blue",
    colourClass: "eyesBlue",
    value: 1222,
    total: 10000,
    score: 2
  },
  {
    title: "Green",
    colourClass: "common",
    value: 891,
    total: 10000,
    score: 3
  },
  {
    title: "Turquoise",
    colourClass: "eyesTurquoise",
    value: 658,
    total: 10000,
    score: 4
  },
  {
    title: "Pink",
    colourClass: "eyesPink",
    value: 506,
    total: 10000,
    score: 5
  },
  {
    title: "Amber",
    colourClass: "extraordinary",
    value: 299,
    total: 10000,
    score: 6
  }
]

const eyeSizes = [
  {
    title: "Small",
    colourClass: "noteworthy",
    value: 2868,
    total: 10000,
    score: 1
  },
  {
    title: "Medium",
    colourClass: "noteworthy",
    value: 5193,
    total: 10000,
    score: 0
  },
  {
    title: "Large",
    colourClass: "noteworthy",
    value: 1939,
    total: 10000,
    score: 2
  },
]

const traits = [
  {
    title: "Common",
    colourClass: "common",
    value: 4003,
    total: 10000,
    score: 0
  },
  {
    title: "Unusual",
    colourClass: "unusual",
    value: 2587,
    total: 10000,
    score: 1
  },
  {
    title: "Noteworthy",
    colourClass: "noteworthy",
    value: 1700,
    total: 10000,
    score: 2
  },
  {
    title: "Remarkable",
    colourClass: "remarkable",
    value: 1055,
    total: 10000,
    score: 3
  },
  {
    title: "Extraordinary",
    colourClass: "extraordinary",
    value: 655,
    total: 10000,
    score: 4
  }
]

const levels = [
  {
    title: "Common",
    colourClass: "common",
    value: 5000,
    total: 10000,
    score: 0
  },
  {
    title: "Unusual",
    colourClass: "unusual",
    value: 3000,
    total: 10000,
    score: 1
  },
  {
    title: "Noteworthy",
    colourClass: "noteworthy",
    value: 1200,
    total: 10000,
    score: 2
  },
  {
    title: "Remarkable",
    colourClass: "remarkable",
    value: 600,
    total: 10000,
    score: 3
  },
  {
    title: "Extraordinary",
    colourClass: "extraordinary",
    value: 200,
    total: 10000,
    score: 4
  }
]

const albino = [
  {
    title: "Yes",
    colourClass: "remarkable",
    value: 60,
    total: 10000,
    score: 1
  },
  {
    title: "No",
    colourClass: "remarkable",
    value: 9940,
    total: 10000,
    score: 0
  }
] */
