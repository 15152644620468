import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Carousel from 'react-bootstrap/Carousel'
import Data from "../../repositories/data";
const storageRoot = "https://firebasestorage.googleapis.com/v0/b/conservation-nft.appspot.com/o/"
const storageStoreRoot = storageRoot+"web%2Fstore%2F"
const storageStoreProductsRoot = storageStoreRoot+"products%2F"

function isOfStatus(status) {
    return function(product) {
        return product.status === status
    }
}

const Store = () => {
    const [hoveredProduct, setHoveredProduct] = React.useState(null)

    const ProductCarouselItem = (title, text, textLink, image, imageAlt) => {
        return (
            <Carousel.Item key={"product-"+image}>
                <div className="imageContainer">
                    <div className="floating"
                        onMouseUp={e => {
                            if(textLink) window.location.href = textLink
                        }}
                        onMouseEnter={e => {
                            setHoveredProduct(title)
                        }}
                        onMouseLeave={e => {
                            setHoveredProduct(null)
                        }} style={{opacity: hoveredProduct === title ? "100%" : "0%", cursor: textLink ? "pointer" : "default"}}>
                        <div className="band">
                            <a href={textLink}>{text}</a>
                        </div>
                    </div>
                    <img src={storageStoreRoot+image+"?alt=media"} alt={imageAlt} className="d-block w-100 h-100"/>
                </div>
            </Carousel.Item>
        )
    }

    const ProductCarousel = (product) => {
        return (
            <Col xs={6} md={3} key={"product-"+product.title} className="product">
                <h3>{product.title}</h3>
                <Carousel className="productCarousel" controls={false} indicators={false} interval={product.interval}>
                    {product.images.map((image, index) => 
                        ProductCarouselItem(product.title, product.text, product.textLink, image, product.imageAlts[index])
                    )}
                </Carousel>
                <a href={product.textLink} style={{display: isTouchDevice() ? "block" : "none"}} className="touchLink">{product.text}</a>
            </Col>
        )
    }

    const ProductItem = (productId, productItem) => {
        return (
            <Col xs={6} md={3} key={"productItem-"+productItem.title} className="productItem">
                <a href={"/store/"+productId+"/"+productItem.id}><img src={storageStoreProductsRoot+productItem.images[0]+"?alt=media"} alt={"Example "+productItem.title}></img></a>
                <h5>{productItem.title}</h5>
                <a href={"/store/"+productId+"/"+productItem.id} className="buttonLink primary">Buy</a>
            </Col>
        )
    }

    return (
        <div className="baseContainer storeContainer">
            <section id="products">
                <Container className="raisedContentContainer">
                    <h1>Stickers</h1>
                    {Data.products.filter(isOfStatus("Available")).map((product) =>
                        <Row className="justify-content-md-center" key={"product-"+product.title}>
                            {product.items.map((productItem) =>
                                ProductItem(product.id, productItem)
                            )}
                        </Row>
                    )}
                </Container>
                <Container className="raisedContentContainer">
                    <h1>Coming Soon</h1>
                    <Row className="justify-content-md-center">
                        {Data.products.filter(isOfStatus("Upcoming")).map((product) =>
                            ProductCarousel(product)
                        )}
                    </Row>
                </Container>
            </section>
        </div>
    )
}

function isTouchDevice() {
    return (('ontouchstart' in window) ||
       (navigator.maxTouchPoints > 0) ||
       (navigator.msMaxTouchPoints > 0));
  }

export default Store;