import React from 'react'

function NavigationItems(props) {
  return (
    <nav className={"navContainer "+props.className}>
      <ul>
        <li><a href="/species">Species</a></li>
        <li><a href="/intel">Intel</a></li>
        <li><a href="/team">Team</a></li>
      </ul>
    </nav>
  )
}

  // <li><a href="/collectibles">Collectibles</a></li>
  // <li><a href="/store">Store</a></li>

export default NavigationItems;