import React from 'react';
import { useParams } from 'react-router-dom';
import ModelViewer from '../ModelViewer'

const ModelPreview = () => {
    const { name } = useParams();
    return (
        <ModelViewer src={"https://firebasestorage.googleapis.com/v0/b/conservation-nft.appspot.com/o/models%2F"+name+".glb?alt=media"} />
    )
}

export default ModelPreview;