import React, { useState } from "react";
import { useParams } from 'react-router-dom';
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Data from "../../repositories/data";
import { loadStripe } from '@stripe/stripe-js';
import Firebase from '../Firebase';
const firebase = new Firebase();
const storageRoot = "https://firebasestorage.googleapis.com/v0/b/conservation-nft.appspot.com/o/"
const storageStoreRoot = storageRoot+"web%2Fstore%2F"
const storageStoreProductsRoot = storageStoreRoot+"products%2F"
const dev = window.location.href.includes("localhost") || window.location.href.includes("https://dev.");
const environment = dev ? 'dev' : 'prod';
const stripePromise = loadStripe(dev ? 'pk_test_51LfehLH3AY1dbrXpPc7HtlQ2w1u8Gd5UuWFzLjZn2clFBz0BQUHQDgqg3uLTrJL47bgBy8pqv2Ptcq1idL5xNZBR00NKCSRhke' : 'pk_live_51LfehLH3AY1dbrXpZgMy7U3nkqKbMz7cFTwULyTy9xkVhUu3yA8WmbTikTCTE0HS1IcepnjIWcFgGkMpjAsfWvMd00OxceEQYt');


const StoreProductItem = () => {
    const { productId, productItemId } = useParams();
    const product = Data.products.filter(function(p) { return p.id === productId })[0];
    const productItem = product.items.filter(function(p) { return p.id === productItemId })[0];
    const [isLoading, setIsLoading] = useState(false);

    const initiatePurchase = () => {
        if(isLoading) return;
        setIsLoading(true);
        var obj = {
            dev: dev,
            priceId: productItem.priceIds[environment],
            successUrl: window.location.origin+"/payment-success",
            cancelUrl: window.location.href
        }
        var createCheckoutSession = firebase.functions.httpsCallable("createCheckoutSession");
        createCheckoutSession(obj)
        .then(function(session) {
            openCheckout(session.data.id)
        }).catch(function (error) {
            setIsLoading(false);
            alert(error.message)
        });
    }

    const openCheckout = async (sessionId) => {
        const stripe = await stripePromise;
        const { error } = await stripe.redirectToCheckout({
            sessionId: sessionId
        });
        if(error) alert(error.message);
    }

    return (
        <div className="baseContainer storeProductItemContainer">
            <Container className="raisedContentContainer">
                <Row>
                    <Col md={7}>
                        <h1>{productItem.title}</h1>
                        <p>{productItem.description}</p>
                    </Col>
                    <Col md={5}>
                        <img src={storageStoreProductsRoot+productItem.images[0]+"?alt=media"} alt={"Example "+productItem.title}></img>
                        <p className="prices">{productItem.price}</p>
                        <button className="buttonLink primary" disabled onClick={initiatePurchase}>{isLoading ? "Loading..." : "Out of stock"}</button>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default StoreProductItem;