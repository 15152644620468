import React from 'react';
import { useParams } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import data from '../../repositories/data';
import ModelViewer from '../ModelViewer';

const storageRoot = "https://firebasestorage.googleapis.com/v0/b/conservation-nft.appspot.com/o/"
const storageSpeciesRoot = storageRoot+"species%2F"
const storageAniblesRoot = storageRoot+"anibles%2F"

const Species = () => {
    const { id } = useParams();
    const species = data.species.filter(function(s) { return s.id === id })[0];

    return (
        <div className="baseContainer speciesContainer">
            <Container className="raisedContentContainer">
            { species ?
                <>
                    <Row>
                        <Col sm={6}>
                            <h1>{species.name}</h1>
                            <p>{species.description}</p>
                            <h3>Trait Categories</h3>
                            <p>{species.traitCategories[0]}, {species.traitCategories[1]}, {species.traitCategories[2]}</p>
                        </Col>
                        <Col sm={6}>
                            <ModelViewer src={storageSpeciesRoot+(id === "Unknown" ? "Drone" : id)+".glb?alt=media"} showShadow={id !== "Unknown"} ar={false}/>
                        </Col>
                    </Row>
                    { species.mainCharacter &&
                        <Row>
                            <Col sm={6}>
                                <h3>Main Character</h3>
                                <h4>{species.mainCharacter.name}</h4>
                                <p>{species.mainCharacter.description}</p>
                            </Col>
                            <Col className="mainCharacterImageContainer">
                                <img src={storageAniblesRoot+species.mainCharacter.name+".jpg?alt=media"} alt={"The "+species.name+" main character, "+species.mainCharacter.name} />
                            </Col>
                        </Row>
                    }
                </>
            :
                <p>Invalid species</p>
            }
            </Container>
            <Container className="backToSpeciesContainer"><a href="/species"><b>&lt; Back to species</b></a></Container>
        </div>
    )
}

export default Species;