import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import RotatingImage from "./rotatingImage";
import Data from "../../repositories/data";

const storageRoot = "https://firebasestorage.googleapis.com/v0/b/conservation-nft.appspot.com/o/"
const storageSpeciesRoot = storageRoot+"species%2F"
const storageAniblesRoot = storageRoot+"anibles%2F"

const NFTs = () => {
    return (
        <div className="baseContainer nftsContainer">
            <section id="nfts">
                <Container className="raisedContentContainer">
                    <h1>Digital Collectibles</h1>
                    <h2>60 species · 1.6m+ potential variations · 1 collection</h2>
                    <Row className="showcase rotating">
                        <Col xs={{span: 12, offset: 0}} md={{span: 4, offset: 0}}>
                            <RotatingImage images={createAllShowcaseImages(storageSpeciesRoot).slice(0, 4)} interval={650} />
                        </Col>
                        <Col xs={{span: 12, offset: 0}} md={{span: 4, offset: 0}}>
                            <RotatingImage images={createAllShowcaseImages(storageSpeciesRoot).slice(5, 9)} interval={1000} />
                        </Col>
                        <Col xs={{span: 12, offset: 0}} md={{span: 4, offset: 0}}>
                            <RotatingImage images={createAllShowcaseImages(storageSpeciesRoot).slice(10, 13)} interval={880} />
                        </Col>
                    </Row>

                    <Row className="typesContainer">
                        <Col>
                            <h2>Anible Types</h2>
                            <Row>
                                <Col md={6} lg={3}>
                                    <h4>Base</h4>
                                    <img src={storageSpeciesRoot+"GrizzlyBear-base.jpg?alt=media"} alt="A base type Anible NFT" />
                                    <p>The foundational and most common type of Anible found on Aniland.</p>
                                </Col>
                                <Col md={6} lg={3}>
                                    <h4>Albino</h4>
                                    <img src={storageSpeciesRoot+"GrizzlyBear-albino.jpg?alt=media"} alt="An albino type Anible NFT" />
                                    <p>With just a handful per species, these Anibles are hard to find but easy to see.</p>
                                </Col>
                                <Col md={6} lg={3}>
                                    <h4>Spirit</h4>
                                    <img src={storageSpeciesRoot+"GrizzlyBear-spirit.jpg?alt=media"} alt="A spirit type Anible NFT" />
                                    <p>These mystical Anibles are said to offer guidance to those in need.</p>
                                </Col>
                                <Col md={6} lg={3}>
                                    <h4>Main Character</h4>
                                    <img src={storageAniblesRoot+"Autumn.jpg?alt=media"} alt="A main character type Anible NFT" />
                                    <p>The rarest Anible type, these will star in stories, toys, and other products.</p>
                                </Col>
                            </Row>
                        </Col>
                    </Row>

                    <Row className="utilityContainer">
                        <Col>
                            <h2>Utility</h2>
                            <Row>
                                {Data.utility.map((utility, index) => 
                                Utility(utility)
                                )}
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </section>
        </div>
    )
}

function createAllShowcaseImages(root) {
    function item(species, type="base") {
      return {url: root+species+"-"+type+".jpg?alt=media", alt: " NFT showcase "+type+" "+species}
    }
    return [item("Buffalo"), item("Elephant"), item("Giraffe"), item("Gorilla"), item("GrizzlyBear"), item("Kangaroo"), item("Lion"), item("WhiteRhino"), item("EmperorPenguin"), item("WhiteRhino", "albino"), item("GreenSeaTurtle"), item("HammerheadShark"), item("Mallard"), item("GreenSeaTurtle", "albino")]
}

const Utility = (utility) => {
    return (
        <Col key={"utility-"+utility.title} className="utilityBox">
        <img src={utility.image} alt={utility.imageAlt} />
        <h5>{utility.title}</h5>
        </Col>
    )
}

export default NFTs;