import React, { useEffect, useState } from 'react'

const RotatingImage = (props) => {
    const [currentImageIndex, setCurrentImageIndex] = useState(0)

    useEffect(() => {
        const params = props
        function chooseNextImage() {
            const nextIndex = (currentImageIndex === params.images.length - 1) ? 0 : currentImageIndex + 1
            setCurrentImageIndex(nextIndex)
        }

        const interval = setInterval(() => {
            chooseNextImage()
        }, props.interval);
        return () => clearInterval(interval);
    }, [currentImageIndex, props]);

    function currentImage() {
        return props.images[currentImageIndex]
    }

    return (
        currentImage() != null ? <img src={currentImage().url} alt={currentImage().alt} /> : <p>Loading...</p>
    )
}

export default RotatingImage;