
import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Data from '../../repositories/data';

const storageRoot = "https://firebasestorage.googleapis.com/v0/b/conservation-nft.appspot.com/o/"
const storageSpeciesRoot = storageRoot+"species%2F"

function isOfType(type) {
    return function(element) {
        return element.type === type
    }
}

const SpeciesList = () => {
    return (
        <div className="baseContainer speciesListContainer">
            <Container className="raisedContentContainer">
                <Row>
                    <Col>
                        <h1>Land</h1>
                        <div className="speciesGrid">
                            {Data.species.filter(isOfType("Land")).map((species) => 
                                Species(species)
                            )}
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <h1>Water</h1>
                        <div className="speciesGrid">
                            {Data.species.filter(isOfType("Water")).map((species) => 
                                Species(species)
                            )}
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <h1>Air</h1>
                        <div className="speciesGrid">
                            {Data.species.filter(isOfType("Air")).map((species) => 
                                Species(species)
                            )}
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

const Species = (species) => {
    return (
      <div className="speciesGridBox" key={species.name}>
        <a href={"/species/"+species.id}>
            <img src={storageSpeciesRoot+species.id+"-base.jpg?alt=media"} alt={"Picture of "+species.name + " species"}></img>
        </a>
      </div>
    )
}


export default SpeciesList;