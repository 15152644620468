import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/functions';
import 'firebase/analytics';
import * as firebaseui from 'firebaseui';

const config = {
    apiKey: "AIzaSyAsBFz1JqPbDP8IqdjBvUHoDpX2novEc_o",
    authDomain: "conservation-nft.firebaseapp.com",
    projectId: "conservation-nft",
    storageBucket: "conservation-nft.appspot.com",
    messagingSenderId: "531274717902",
    appId: "1:531274717902:web:4a4acedec176c1254ece9f",
    measurementId: "G-ZKKLBZHPDB"
  };

class Firebase {
    constructor() {
        if (!firebase.apps.length) { firebase.initializeApp(config); }
        this.Auth = firebase.auth;
        this.auth = firebase.auth();
        this.db = firebase.firestore();
        this.Firestore = firebase.firestore;
        this.ui = firebaseui.auth.AuthUI.getInstance() || new firebaseui.auth.AuthUI(this.auth);
        this.functions = firebase.functions();
        this.analytics = firebase.analytics();
    }
}

export default Firebase;