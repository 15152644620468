import React, { useState, useEffect, useCallback } from 'react';
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSave, faPlusSquare, faMinusSquare } from '@fortawesome/free-solid-svg-icons'
import { Unity, useUnityContext } from "react-unity-webgl";

const baseUrl = "https://firebasestorage.googleapis.com/v0/b/conservation-nft.appspot.com/o/web%2Frangerbuilder%2Fbuild%2F"

const black = "#000000"
const lightBrown = "#B98367"
const darkBrown = "#875F4A"
const darkGrey = "#555450"
const lightGrey = "#ACACAC"
const green = "#6A9062"
const blue = "#4485C1"
const amber = "#fec85d"
const blonde = "#EBE0BB"
const clothesColours = [green, darkGrey, lightGrey, amber]
const eyeColours = [black, lightBrown, darkBrown, blue, green, amber]
const hairColours = [lightBrown, darkBrown, blue, blonde, lightGrey, black]
const feetColours = [lightBrown, darkBrown, green, darkGrey, lightGrey]
const skinColours = ["#F8CABF", "#F8C49C", "#B58861", "#764F26", "#442A14"]
const backgroundColours = ["#66C15C", "#57BED9", "#E998E0", "#A154C8", "#EAB323"]
const mutableTraits = [
    { name: "Skin Colour", type: "Skin", colours: skinColours, colourOnly: true },
    { name: "Head", type: "Head", colours: clothesColours },
    { name: "Hair", type: "Hair", colours: hairColours },
    { name: "Eyes", type: "Eyes", colours: eyeColours },
    { name: "Facial Hair", type: "FacialHair", colours: hairColours },
    { name: "Top", type: "Torso", colours: clothesColours },
    { name: "Sleeves", type: "Sleeves", colours: clothesColours },
    { name: "Legs", type: "Legs", colours: clothesColours },
    { name: "Feet", type: "Feet", colours: feetColours, colourOnly: true },
    { name: "Back Accessory", type: "BackAccessory" },
    { name: "Front Accessory", type: "FrontAccessory" },
    { name: "Legs Accessory", type: "LegsAccessory" },
    { name: "Wrist Accessory", type: "WristAccessory" },
    { name: "Background Colour", type: "ColourBackground", colours: backgroundColours, colourOnly: true }
]
const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

const RangerBuilder = () => {

    const [traits, setTraits] = useState({});
    const [colours, setColours] = useState({});
    const [loadComplete, setLoadComplete] = useState(false);

    const { unityProvider, sendMessage, addEventListener, removeEventListener, isLoaded, loadingProgression } = useUnityContext({
        loaderUrl: baseUrl+"RangerBuilder.loader.js?alt=media",
        dataUrl: baseUrl+"RangerBuilder.data?alt=media",
        frameworkUrl: baseUrl+"RangerBuilder.framework.js?alt=media",
        codeUrl: baseUrl+"RangerBuilder.wasm?alt=media",
        webGLContextAttributes: {
            preserveDrawingBuffer: true,
        }
    });

    useEffect(() => {
        var newColours = {}
        mutableTraits.forEach(mutableTrait => {
            if(mutableTrait.colours) newColours[mutableTrait.type] = mutableTrait.colours[0]
        });
        setColours(newColours)
    }, [])

    useEffect(() => {
        const traitsAreLoaded = Object.keys(traits).length > 0
        const coloursAreLoaded = Object.keys(colours).length > 0
        if(loadComplete === false && traitsAreLoaded && coloursAreLoaded) {
            updateModel(traits)
            updateModelColours(colours)
            // cycle()
        }
        setLoadComplete(traitsAreLoaded)
    /* eslint-disable react-hooks/exhaustive-deps */
    }, [traits]);

    const handleSetTraits = useCallback((traits) => {
        parseTraits(traits)
    /* eslint-disable react-hooks/exhaustive-deps */
    }, []);

    useEffect(() => {
        addEventListener("BroadcastAllTraits", handleSetTraits);
        return () => {
            removeEventListener("BroadcastAllTraits", handleSetTraits);
        };
    }, [addEventListener, removeEventListener, handleSetTraits]);

    function getTraitsArrFromString(traits) {
        var traitsArr = traits.split(",");
        return traitsArr.map(traitString => {
            const split = traitString.split("-");
            return [split[0], split[1]]
        })
    }

    function parseTraits(traitsToParse) {
        // traits = "Head-Beanie,FacialHair-Beard,Hair-BowlCut,Hair-BuzzCut,Hair-Curls,FacialHair-Goatie,FacialHair-Goatie + Moustache,Hair-Mohawk,FacialHair-Moustache,Hair-FlatTop,Head-Bonnie Hat,Feet-Boots,Torso-Crop Top,Head-Cap ,Torso-Sweater,Head-Helmet,Torso-Jacket,Legs-Cargo,Sleeves-Long,Torso-Polo,BackAccessory-Radio,Sleeves-Short,FacialHair-Stubble,FrontAccessory-Radio,WristAccessory-Watch"
        var traitsObj = {}
        for (const trait of getTraitsArrFromString(traitsToParse)) {
            const [type, name] = trait
            const exists = type in traitsObj
            const toAdd = {name: name, selected: !exists}
            if(exists) traitsObj[type].push(toAdd)
            else traitsObj[type] = [toAdd]
        }
        setTraits(traitsObj)
    }

    function indexOfSelected(traitsForType) {
        return traitsForType.findIndex(trait => {
            return trait.selected;
        });
    }

    function change(type, next) {
        var traitsForType = traits[type]
        const selectedIndex = indexOfSelected(traitsForType)
        const increment = next ? 1 : -1
        const resetBoundary = next ? traitsForType.length - 1 : 0
        const afterResetIndex = next ? 0 : traitsForType.length -1
        const desiredIndex = selectedIndex === resetBoundary ? afterResetIndex : selectedIndex + increment;
        traitsForType[selectedIndex].selected = false
        traitsForType[desiredIndex].selected = true
        setTraits(prevState => ({
            ...prevState,
            [type]: traitsForType
        }))
        updateModel(traits)
    }

    function selectedTraitName(type) {
        const traitsForType = traits[type]
        const selectedIndex = indexOfSelected(traitsForType)
        return traitsForType[selectedIndex].name
    }

    function updateModel(traitsToUpdate) {
        const selectedTraits = Object.keys(traitsToUpdate).map(type => {
            var selectedTraitName = ""
            traitsToUpdate[type].forEach(trait => {
                if (trait.selected) selectedTraitName = trait.name
            });
            return type+"-"+selectedTraitName
        })
        sendMessage("GameController", "setSelectedTraits", selectedTraits.join(","));
    }

    function changeColour(colour, type) {
        setColours(prevState => ({
            ...prevState,
            [type]: colour
        }))
    }

    useEffect(() => {
        if(Object.keys(colours).length === 0) return;
        updateModelColours(colours)
    }, [colours])

    function updateModelColours(coloursToUpdate) {
        const selectedColours = Object.keys(coloursToUpdate).map(type => {
            return type+"-"+coloursToUpdate[type]
        })
        sendMessage("GameController", "setSelectedColours", selectedColours.join(","));
    }

    function handleClickTakeScreenshot() {
        sendMessage("GameController", "TakeScreenshot");
    }
    
    function chooseRandoms() {
        var newColours = colours
        for(var mutableTrait of mutableTraits) {
            const type = mutableTrait.type
            if(!("colourOnly" in mutableTrait)) {
                const selectedIndex = indexOfSelected(traits[type])
                const desiredIndex = Math.floor(Math.random() * traits[type].length)
                traits[type][selectedIndex].selected = false
                traits[type][desiredIndex].selected = true
            }
            const possibleColours = mutableTrait.colours
            if(possibleColours) {
                newColours[type] = possibleColours[Math.floor(Math.random() * possibleColours.length)]
            }
        }
        setTraits(prevState => ({
            ...prevState,
            traits
        }))
        updateModel(traits)
        setColours(prevState => ({
            ...prevState,
            newColours
        }))
        updateModelColours(colours)
        
    }

    var i = 0;
    /* eslint-disable no-unused-vars */
    function cycle() {
        return;
        /* eslint-disable no-unreachable */
        setTimeout(function() {
            chooseRandoms()
          i++;
          if (i < 50) {
            cycle();
          }
        }, 300)
    }

    function traitSelectionBox(type, name, colourOnly) {
        return (
            <div className="traitSelectionBox">
                <span className="leftArrow noSelect" onClick={() => change(type, false)}>{ colourOnly ? "" : "◀" }</span>
                <div>
                    <p className="name noSelect">
                        <span className="nameTitle">{name}</span><br />
                        <span className="nameName">{ colourOnly ? "Choose ->" : selectedTraitName(type)}</span>
                    </p>
                    <span className="name noSelect"></span>
                </div>
                <span className="rightArrow noSelect" onClick={() => change(type, true)}>{ colourOnly ? "" : "▶" }</span>
            </div>
        )
    }

    function colourPicker(type, colour) {
        return (
            <div className={"colourPicker" + (colours[type] === colour ? " selected" : "")}
                style={{ backgroundColor: colour }}
                onClick={() => changeColour(colour, type)}
                key={type+"-"+colour}></div>
        )
    }

    function zoom(direction) {
        sendMessage("Camera", "zoom", direction);
    }

    return (
        <Container className="rangerBuilderContainer">                
            { isSafari ? 
                <p className="text-center">This browser is not supported. Please try again on desktop Chrome, Firefox etc.</p>
            :
                <Row>
                    <Col className="d-xs-block d-sm-block d-md-none d-lg-none d-xl-none">
                        <p className="text-center">This browser or resolution is not supported. Please try again on a desktop or resize.</p>
                    </Col>
                    <Col className="d-none d-md-block" md={12} lg={8}>
                        {!isLoaded && (
                            <h3>Loading... {Math.round(loadingProgression * 100)}%</h3>
                        )}
                        <Unity style={{ width:'700px', height: '700px', maxWidth: '100%', visibility: isLoaded ? "visible" : "hidden" }} className="rounded" unityProvider={unityProvider} />
                        <div className="controls" style={{ visibility: Object.keys(traits).length > 0 ? "visible" : "hidden" }}>
                            <FontAwesomeIcon className="controlButton" icon={faMinusSquare} onClick={() => { zoom("out") }} />
                            <FontAwesomeIcon className="controlButton" icon={faPlusSquare} onClick={() => { zoom("in") }}/>
                            <FontAwesomeIcon className="controlButton" icon={faSave} onClick={handleClickTakeScreenshot}/>
                        </div>
                    </Col>
                    <Col className="d-none d-md-block">
                        <div>
                            {
                                Object.keys(traits).length > 0 ?
                                    mutableTraits.map((trait) =>
                                        <Row key={trait.type}>
                                            <Col md={8}>
                                            { traitSelectionBox(trait.type, trait.name, trait.colourOnly) }
                                            </Col>
                                            {
                                                trait.colours ?
                                                    <Col>
                                                        <div className="colourPickerBox">
                                                            {trait.colours.map(colour => {
                                                                return colourPicker(trait.type, colour)
                                                            })}
                                                        </div>
                                                    </Col>
                                                : null
                                            }
                                        </Row>
                                    )
                                :
                                    null
                            }
                        </div>
                    </Col>
                </Row>
            }
        </Container>
    );
}

export default RangerBuilder;