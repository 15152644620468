import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ModelViewer from "../ModelViewer";
import Data from "../../repositories/data";

const storageRoot = "https://firebasestorage.googleapis.com/v0/b/conservation-nft.appspot.com/o/"
const storageModelsRoot = storageRoot+"models%2F"

const Intel = () => {
  const [openedFaq, setOpenedFaq] = React.useState(null);
  const listFaqs = Data.faqs.map((faq, index) => (
    <div key={"faq-" + index}>
      <p
        className="question"
        key={faq.question}
        onClick={() => setOpenedFaq(openedFaq === index ? null : index)}
      >
        <span className="secondary">{openedFaq === index ? "- " : "+ "}</span>
        {faq.question}
      </p>
      {openedFaq === index ? (
        <>
          <p className="answer">{faq.answer}</p>
          {faq.links
            ? faq.links.map((link) => (
                <Row>
                  {link.image ? (
                    <Col>
                      <img
                        src={link.image}
                        alt={link.imageAlt}
                        className="linkImage"
                      />
                    </Col>
                  ) : null}
                  <Col>
                    <a
                      key={link.link}
                      href={link.link}
                      target="_blank"
                      rel="noopener noreferrer"
                      className={link.image ? "withLink" : null}
                    >
                      {link.text}
                    </a>
                  </Col>
                </Row>
              ))
            : null}
        </>
      ) : null}
    </div>
  ));

  return (
    <div className="baseContainer intelContainer">
      <section id="causes">
        <Container>
          <Row>
            <Col md={5} className="percentageContainer">
              <ModelViewer src={storageModelsRoot+"elephant.gltf?alt=media"} />
            </Col>
            <Col md={7}>
              <h1>Causes</h1>
              <div className="contentBox primary rounded">
                <p>
                  "We don't own the planet Earth, we belong to it. And we must
                  share it with our wildlife"
                  <br />
                  <br />
                  <b className="secondary">- Steve Irwin</b>
                </p>
                <p>
                  Our Anibles were created to bring funding for conservation
                  into the digital era.
                </p>
                <p>
                  The organisations we support are so picked because they do
                  amazing, boots on the ground work to save the lives of
                  animals.
                </p>
                <p>
                  We will post updates from them so you can see the real world
                  impact of your Anibles.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <section id="loreAndDesign">
        <Container>
          <Row>
            <Col md={7}>
              <h1>Lore &amp; Design Decisions</h1>
              <div className="contentBox primary rounded">
                <p>From the name 'Anibles', to the shape of the Anibles themselves, there is a reason behind everything.</p>
                <h3>Anibles</h3>
                <p>'Ani' - animal | 'bles' - blocky</p>
                <h3>Abstraction</h3>
                <p>Our name, logo, models, stories, and the underlying realities - they're all abstracted.</p>
                <p>The current wildlife communication model of guilt through negative emotions turns people away. Our ethos of positivity, innovation, and abstraction is why we stand out.</p>
                <h3>Stories</h3>
                <p>Stories, especially those about animals, are an amazing vehicle for entertainment and education. Animals are members of a natural order full of changeless beauty and spirituality, giving a depth to stories about them that we can connect with meaningfully.</p>
                <p>Combine that with abstracted and methaphorical tales of human interactions with animals and their habitats, and you have a powerful and timeless combination.</p>
                <p>In addition, the futuristic setting of the Anibles story allows us to present a vision of how we can live in harmony with nature if we put our minds to it.</p>
                <p>We know that the reality of wildlife's plight is more complex than a hero/villain story, which is why there is nuance within our villain, Greedy Inc. From the employee who must feed his family, to the demand for their products coming from unknowing consumers, we're making sure to represent the complexities.</p>
              </div>
            </Col>
            <Col md={5} className="verticalCenter">
              <ModelViewer src={storageModelsRoot+"giraffe.glb?alt=media"} />
            </Col>
          </Row>
        </Container>
      </section>

      {/* <section id="faq">
        <Container>
          <Row>
            <Col md={5} className="verticalCenter">
              <ModelViewer src={storageModelsRoot+"gorilla.gltf?alt=media"} />
            </Col>
            <Col md={7}>
              <h1>FAQ</h1>
              <div className="contentBox secondary rounded faqList">
                {listFaqs}
              </div>
            </Col>
          </Row>
        </Container>
      </section> */}
    </div>
  );
};

export default Intel;
