import React from 'react'
import Dropdown from 'react-bootstrap/Dropdown'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Data from '../../repositories/data.js'

const Calculator = () => {
    const [calculatorConfig, setCalculatorConfig] = React.useState({})

    const updateCalculatorConfig = (key, value) => {
        var newConfig = Object.assign({}, calculatorConfig)
        newConfig[key] = value
        setCalculatorConfig(newConfig)
      }
    
      const calculatorComponent = (title, key, values) => {
        return (
          <Row>
            <Col xs={6}>
              <Dropdown>
                <Dropdown.Toggle variant="success" id="dropdown-basic">
                  { key in calculatorConfig ? calculatorConfig[key].title : title }
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  {values.map((value) =>
                    <Dropdown.Item key={value.title} onClick={() => updateCalculatorConfig(key, value)}>{value.title}</Dropdown.Item>
                  )}
                </Dropdown.Menu>
              </Dropdown>
            </Col>
            <Col xs={6}>
              { key in calculatorConfig ? calculatorConfig[key].score /*readablePercentage(calculatorConfig[key].value, calculatorConfig[key].total)+"%"*/ : "⬅️ Please select" }
            </Col>
          </Row>
        )
      }
    
      const calculatorTotal = () => {
        function calculateTotalScore(items) {
          return 1 + Object.values(items).map((item) => item.score).reduce((a, b) => a + b)
        }
        function calculateRarityLevel(items) {
          const score = calculateTotalScore(items)
          const allTraitCategories = ["Common", "Unusual", "Noteworthy", "Remarkable", "Extraordinary"]
          if (score >= 1 && score <= 5) return allTraitCategories[0]
          if (score >= 6 && score <= 10) return allTraitCategories[1]
          if (score >= 11 && score <= 15) return allTraitCategories[2]
          if (score >= 16 && score <= 20) return allTraitCategories[3]
          if (score >= 21 && score <= 25) return allTraitCategories[4]
          return "Unranked"
        }
        return (
          <>
            <p>Rarity score (? = rarest, ? = most common):</p>
            <h4>{Object.values(calculatorConfig).length > 0 /*=== 7*/ ? calculateRarityLevel(calculatorConfig) : "Please choose all values above."}</h4>
            <h4>{Object.values(calculatorConfig).length > 0 /*=== 7*/ ? calculateTotalScore(calculatorConfig) : ""}</h4>
          </>
        )
    }

    return (
        <Container>
            <Row>
                <Col md={{span: 8, offset: 2}}>
                    <div className="rarityCalculator">
                        <h2>Rarity Calculator</h2>
                        {calculatorComponent("Size", "size", Data.sizes)}
                        {calculatorComponent("Eye Colour", "eyeColour", Data.eyeColours)}
                        {calculatorComponent("Eye Size", "eyeSize", Data.eyeSizes)}
                        {calculatorComponent("Special Trait 1", "trait1", Data.traits)}
                        {calculatorComponent("Special Trait 2", "trait2", Data.traits)}
                        {calculatorComponent("Special Trait 3", "trait3", Data.traits)}
                        {calculatorComponent("Albino", "albino", Data.albino)}
                        {calculatorTotal()}
                    </div>
                </Col>
            </Row>
        </Container>
    )
}

export default Calculator;