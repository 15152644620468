
import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Data from '../../repositories/data';

const Home = () => {
    return (
        <div className="baseContainer teamContainer">
            <Container className="raisedContentContainer">
                <Row>
                <Col>
                    <h1>Team</h1>
                    <Row>
                        {Data.people.map((person, index) => 
                            Person(person, person.centered, index)
                        )}
                    </Row>
                </Col>
                </Row>
            </Container>
        </div>
    )
}

const Person = (person, centered, index) => {
    return (
      <Col xs={12} md={{span: 6, offset: centered ? 3 : 0}} className="personContainer" key={"person-"+index}>
        <h4>{person.name}</h4>
        <h5>{person.role}</h5>
        <img src={person.image} alt={"Picture of "+person.name}></img>
        <p>{person.description}</p>
        {person.link ? <a href={person.link} target="_blank" rel="noopener noreferrer">{person.linkText}</a>:null}
      </Col>
    )
  }

export default Home;