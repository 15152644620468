import React from 'react';
import "@google/model-viewer/dist/model-viewer";

const ModelViewer = (props) => {
    const showShadow = props.showShadow === undefined ? true : props.showShadow;
    const ar = props.ar === undefined ? true : props.ar;
    const cameraControls = props.cameraControls === undefined ? true : props.cameraControls;
    return (
        <model-viewer style={{width: "100%", height: (props.height ? props.height : 400)+"px", margin: "0 auto"}} loading="eager" bounds="tight" src={props.src} ar={ar ? true : null} ar-modes="webxr scene-viewer quick-look" poster-color="transparent" camera-controls={cameraControls ? true : null} environment-image="neutral" shadow-intensity={showShadow ? "1" : "0"} auto-rotate rotation-per-second="250%" disable-zoom>
            <div className="progress-bar hide" slot="progress-bar">
                <div className="update-bar"></div>
            </div>
        </model-viewer>
    )
}

export default ModelViewer;